import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center',
        marginTop: '40px',
        marginBottom: '24px',
        fontWeight: 'Bold',
        fontSize: '24px',},
    content: {
        padding: '40px',
        paddingTop: '50px',
        fontSize: '16px',},
    nowrap: {
        whiteSpace: "nowrap",},}));

function MentionLegale(props) {
    const classes = useStyles();
    const {company} = props;
    const companyMail = "support@" + company.toLowerCase() + ".fr";

    return (
        <div className={classes.content}>
            <Typography variant="h6" className={classes.root}>
                Mention Légale</Typography>
            <Typography paragraph>
                {company} est édité par :</Typography>
            <Typography paragraph>
                {company} SAS<br/>
                Société par Actions Simplifiées au capital de <span className={classes.nowrap}>16 000,00 €</span><br/>
                Siège social : 88 rue de Chasselas,
                <span className={classes.nowrap}> 34 430</span>
                <span className={classes.nowrap}> Saint-Jean-de-Védas</span><br/>
                RCS Montpellier : 841 341 407 00010<br/>
                TVA Communautaire FR 37 841 341 407<br/>
                Téléphone : 06.01.29.60.50</Typography>
            <Typography paragraph>
                Directeur de la publication : Olivier Vinchon</Typography>
            <Typography paragraph>
                CNIL :</Typography>
            <Typography paragraph>
                Numéro de déclaration :
                Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez
                d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en
                vous adressant à <a href={"mailto:" + companyMail}>{companyMail}</a></Typography>
            <Typography paragraph>
                Hébergeur :</Typography>
            <Typography paragraph>
                QUIDDEM SAS<br/>
                125 Rue Gilles Martinet<br/>
                34070 Montpellier<br/></Typography>
            <Typography paragraph>
                Nous vous rappelons que toute copie, même partielle, de quelques éléments que ce soit du site {company}
                est interdite. Le non-respect de cette interdiction constitue une contrefaçon susceptible d’engager la
                responsabilité civile et pénale du contrefacteur.</Typography></div>)}

MentionLegale.propTypes  = {
    company: PropTypes.string.isRequired,};

export default MentionLegale
