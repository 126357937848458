import React from 'react';
import {makeStyles, Typography, Paper, Grid} from "@material-ui/core";
import EssenceComponent from "./EssenceComponent";

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: '8%',
        backgroundImage: 'url(/image/bgEssence.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '35%',
        backgroundPosition: 'top left',
    },
    paper: {
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '80%',
        textAlign: 'center',
        padding: '5%',
        boxShadow: '0px 3px 30px #E3ECFF',
        borderRadius: '15px',
    },
    title: {
        color: '#1C205F',
        marginBottom: '50px',
    },
}));

const Essence = React.forwardRef((props, ref) =>  {
    const classes = useStyles();

    return (
        <div className={classes.root} ref={ref}>
            <Paper className={classes.paper}>
                <Typography variant={"h3"} className={classes.title}>Entreprise libérée par essence</Typography>
                <Grid container justify={"space-evenly"}>
                    <Grid item>
                        <EssenceComponent image={'/image/imgItemOne.svg'}
                                          text={"CoderKaine, animée en toute autonomie par ses équipes, déploie sa " +
                                          "croissance en alliant l’humain et les nouvelles technologies."}
                        />
                    </Grid>
                    <Grid item>
                        <EssenceComponent image={'/image/imgItemTwo.svg'}
                                          text={"Son intelligence collective exalte le talent de ses collaborateurs " +
                                          "autour d’une culture et une raison d’être commune qui lui permet de " +
                                          "transcender les besoins de ses clients."}
                        />
                    </Grid>
                    <Grid item>
                        <EssenceComponent image={'/image/imgItemThree.svg'}
                                          text={"Libérée par la confiance, nourrie par l’innovation et grandie par " +
                                          "l’estime de ses clients, CoderKaine incube, finance et innove dans des " +
                                          "startups."}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>

    )
});

export default Essence
