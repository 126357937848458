import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import ProduitsComponent from "./ProduitsComponent";

const useStyles = makeStyles(() => ({
    root: {
        textAlign: "center",
        paddingTop: '100px',
        marginBottom: '75px',
    },
    title: {
        color: '#1C205F',
    }
}));

const Produits = React.forwardRef((props, ref) =>  {
    const classes = useStyles();

    return (
        <div className={classes.root} ref={ref}>
            <Typography variant={"h3"} className={classes.title}>Nos produits</Typography>
            <ProduitsComponent
                image={"/image/hfwAppScreen.png"}
                title={"Hifivework"}
                text={"L'essentiel de la gestion RH catalyseur de Richesses Humaines"}
                bgImage={"/image/bgProduitHFW.png"}
                isLeft={true}
                color={'#0033ff'}
                linkToProduct={"https://www.hifivework.com/"}
                logoProduit={"image/logoHfw.png"}/>
            <ProduitsComponent
                image={"/image/imgProduitResilive.png"}
                title={"Resilive"}
                text={"Le All-In-One de la résidence"}
                bgImage={"/image/bgProduitResilive.png"}
                isLeft={false}
                color={'#FFEAD0'}
                linkToProduct={"http://www.resilive.fr/#/"}
                logoProduit={"image/logoResilive.png"}/>
        </div>
    )
});

export default Produits
