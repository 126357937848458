import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    root: {
        width: '280px',
    },
    imageContainer: {
        display: 'flex',
        height: '350px',
        width: '100%',
    },
    image: {
        width: '100%',
    },
    text: {
        textAlign: 'left',
    },
}));

function EssenceComponent(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.imageContainer}>
                <img alt={""} src={props.image} className={classes.image}/>
            </div>
            <Typography variant={"subtitle2"} className={classes.text}>{props.text}</Typography>
        </div>
    )
}

EssenceComponent.propTypes  = {
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default EssenceComponent
