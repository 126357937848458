import React from 'react';
import {makeStyles, Typography, Grid} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center',
        width: theme.spacing(38),
    },
    name: {
        marginTop: '10px',
        marginBottom: '20px',
        fontSize: '25px',
    },
    surname: {
        fontSize: theme.spacing(2.5),
        fontWeight: '600',
    },
    role: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(2),
    },
    image: {
        width: theme.spacing(23.75),
        backgroundImage: 'url(/image/imgBgTeamcomponant.png)',
        backgroundSize: '90%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 80%',
    },
    link: {
        textDecoration: 'none',
        color: localStorage.getItem('themeMode') === "dark" ? 'white' : 'black',
    },
}));

function TeamComponent(props) {
    const classes = useStyles(props);

    return (
        <Grid container alignItems={'center'} justify={'center'}>
            {props.teammates.map((mate, index) =>
                mate.link ?
                    <Grid item className={classes.container} key={index}>
                        <a href={mate.link} target="_blank" rel="noopener noreferrer" className={classes.link}
                           key={index}>
                            <img alt={""} src={mate.image} className={classes.image}/>
                            <Typography className={classes.name}>{mate.name}</Typography>
                            <Typography className={classes.surname}>{mate.surname}</Typography>
                            <Typography className={classes.role}>{mate.role}</Typography>
                        </a>
                    </Grid>
                    :
                    <Grid item className={classes.container} key={index}>
                        <img alt={""} src={mate.image} className={classes.image}/>
                        <Typography className={classes.name}>{mate.name}</Typography>
                        <Typography className={classes.surname}>{mate.surname}</Typography>
                        <Typography className={classes.role}>{mate.role}</Typography>
                    </Grid>
            )}
        </Grid>
    )
}

export default TeamComponent
