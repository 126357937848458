import React from 'react';
import {Hidden, makeStyles, Typography, Grid} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    rootLeft: {
        float: 'left',
        marginTop: '50px',
        marginBottom: '50px',
    },
    rootRight: {
        float: 'right',
        marginTop: '50px',
        marginBottom: '50px',
    },
    textContainer: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    rectangle: {
        width: '100px',
        height: '8px',
        borderRadius: '10px',
        backgroundColor: props => props.color,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '14px',
    },
    image: {
        maxHeight: '350px',
        marginTop: '80px',
        marginBottom: '40px',
        boxShadow: props => '0px 3px 30px ' + props.color,
        maxWidth: '100%'
    },
    imageRight: {
        maxHeight: '350px',
        marginTop: '80px',
        marginBottom: '40px',
        boxShadow: props => '0px 3px 30px ' + props.color,
        maxWidth: '100%'
    },
    imageContainer: {
        paddingLeft: '15%',
        backgroundImage: props => props.bgImage ? 'url(' + props.bgImage + ')' : '',
        backgroundSize: '85% 100%',
        backgroundRepeat: 'no-repeat',
    },
    imageContainerRight: {
        paddingRight: '15%',
        backgroundImage: props => props.bgImage ? 'url(' + props.bgImage + ')' : '',
        backgroundSize: '85% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
    },
    linkToProduct: {
        textDecoration: 'none',
        color: 'inherit'
    },
    logoProduit: {
        maxHeight: 100,
        maxWidth: 100
    },
    text: {
        marginTop: 8
    }
}));

function ProduitsComponent(props) {
    const classes = useStyles(props);

    return (
        <>
            {props.isLeft ?
                <Grid container justify={"flex-start"} alignItems={"center"} className={classes.rootLeft}>
                    <Hidden only={['xs', 'sm']}>
                        <Grid item className={classes.imageContainer} md={6} lg={6} xl={6}>
                            <img alt={""} src={props.image} className={classes.image}/>
                        </Grid>
                        <Grid item md={4} lg={4} xl={3}>
                            <div className={classes.textContainer}>
                                <Typography className={classes.linkToProduct} component={"a"} variant={"h4"}
                                            href={props.linkToProduct} target="_blank" rel="noopener noreferrer">
                                    {props.title}
                                </Typography>
                                <br/>
                                <Typography className={classes.text} variant={"subtitle2"}>{props.text}</Typography>
                                <br/>
                                <div className={classes.rectangle}/>
                                <br/>
                                <a href={props.linkToProduct} target="_blank" rel="noopener noreferrer">
                                    <img className={classes.logoProduit} src={props.logoProduit}
                                         alt={"image du produit " + props.title}/>
                                </a>
                            </div>
                        </Grid>
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Grid item xs={12} sm={12}>
                            <div className={classes.textContainer}>
                                <Typography className={classes.linkToProduct} component={"a"} variant={"h4"}
                                            href={props.linkToProduct} target="_blank" rel="noopener noreferrer">
                                    {props.title}
                                </Typography>
                                <br/>
                                <Typography className={classes.text} variant={"subtitle2"}>{props.text}</Typography>
                                <br/>
                                <div className={classes.rectangle}/>
                                <br/>
                                <a href={props.linkToProduct} target="_blank" rel="noopener noreferrer">
                                    <img className={classes.logoProduit} src={props.logoProduit}
                                         alt={"image du produit " + props.title}/>
                                </a>
                            </div>
                        </Grid>
                        <Grid item className={classes.imageContainer} xs={12} sm={12}>
                            <img alt={""} src={props.image} className={classes.image}/>
                        </Grid>
                    </Hidden>
                </Grid>
                :
                <Grid container justify={"flex-end"} alignItems={"center"} className={classes.rootRight}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                        <div className={classes.textContainer}>
                            <Typography className={classes.linkToProduct} component={"a"} variant={"h4"}
                                        href={props.linkToProduct} target="_blank" rel="noopener noreferrer">
                                {props.title}
                            </Typography>
                            <br/>
                            <Typography className={classes.text} variant={"subtitle2"}>{props.text}</Typography>
                                <br/>
                            <div className={classes.rectangle}/>
                                <br/>
                            <a href={props.linkToProduct} target="_blank" rel="noopener noreferrer">
                                <img className={classes.logoProduit} src={props.logoProduit}
                                     alt={"image du produit " + props.title}/>
                            </a>
                        </div>
                    </Grid>
                    <Grid item className={classes.imageContainerRight} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <img alt={""} src={props.image} className={classes.imageRight}/>
                    </Grid>
                </Grid>
            }
        </>
    )
}

ProduitsComponent.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isLeft: PropTypes.bool.isRequired,
    linkToProduct: PropTypes.string.isRequired,
    logoProduit: PropTypes.string.isRequired,
};

export default ProduitsComponent
