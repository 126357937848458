import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MentionLegale from "../../components/MentionLegale";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#1C205F',
        marginTop: '100px',
        paddingTop: '4%',
        paddingLeft: '8%',
        paddingRight: '8%',
        color: 'white',
    },
    container: {
        marginTop: '10px',
    },
    title: {
        marginRight: '10px',
    },
    titleContainer: {
        width: '300px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    flex: {
        display: 'flex',
    },
    rectangle: {
        width: '100%',
        height: '1px',
        backgroundColor: '#D3D3D3',
        marginBottom: '20px',
        marginTop: '20px',
    },
    footerMention: {
        float: 'right',
        "&:hover": {cursor: 'pointer'},
    },
    button: {
        color: 'white',
        backgroundColor: '#1C205F',
        float: 'right'
    },
    spacer: {
        flexGrow: 1,
    },
    link: {
        "&:hover": {cursor: 'pointer'},
    },
    linkProducts: {
        textDecoration: 'none',
        color: '#fff',
    },
}));

function Footer(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <footer className={classes.root}>
            <Grid container justify={"space-between"}>
                <Grid item sm={'auto'} xs={10}>
                    <div className={classes.container}>
                        <Typography variant={"h4"} paragraph>
                            <span className={classes.title}>CoderKaine</span></Typography>
                        <Typography variant={"subtitle2"} paragraph>Libérateur d’intelligence collective</Typography>
                        <a href={'https://www.linkedin.com/company/coderkaine'} target="_blank"
                           rel="noopener noreferrer">
                            <input alt={""} type={'image'} src={'/image/linkedin.svg'}/>
                        </a>
                    </div>
                </Grid>
                <Grid item sm={'auto'} xs={10}>
                    <div className={classes.container}>
                        <Typography variant={"h4"} paragraph onClick={() => props.scrollTo("produits")}>
                            Nos produits
                        </Typography>
                        <a href={'http://www.hifivework.com/'} target="_blank" rel="noopener noreferrer"
                           className={classes.linkProducts}>
                            <Typography variant={"subtitle2"} paragraph
                                        className={classes.linkProducts}>
                                Hifivework
                            </Typography>
                        </a>
                        <a href={'http://www.resilive.fr/#/'} target="_blank" rel="noopener noreferrer"
                           className={classes.linkProducts}>
                            <Typography variant={"subtitle2"} paragraph
                                        className={classes.linkProducts}>
                                Resilive
                            </Typography>
                        </a>
                    </div>
                </Grid>
                <Grid item sm={'auto'} xs={10}>
                    <div className={classes.container}>
                        <Typography variant={"h4"} paragraph>Liens</Typography>
                        <Typography variant={"subtitle2"} paragraph onClick={() => props.scrollTo("home")}
                                    className={classes.link}>Accueil</Typography>
                        <Typography variant={"subtitle2"} paragraph onClick={() => props.scrollTo("essence")}
                                    className={classes.link}>À propos de Coderkaine</Typography>
                        <Typography variant={"subtitle2"} paragraph onClick={() => props.scrollTo("valour")}
                                    className={classes.link}>Nos valeurs</Typography>
                        <Typography variant={"subtitle2"} paragraph onClick={() => props.scrollTo("contact")}
                                    className={classes.link}>Contactez-nous</Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.rectangle}/>
                    <Typography className={classes.footerMention} variant={"subtitle2"}
                                onClick={() => setOpen(true)} paragraph>
                        Mentions légales</Typography>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose} fullScreen>
                <AppBar>
                    <Toolbar>
                        <div className={classes.spacer}/>
                        <CloseIcon className={classes.button} onClick={handleClose}/>
                    </Toolbar>
                </AppBar>
                <MentionLegale company={'CoderKaine'}/>
            </Dialog>
        </footer>
    )
}

export default Footer
