import React from 'react';
import {makeStyles, Typography, Grid, Button} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '50px',
    },
    textContainer: {
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    title: {
        fontSize: theme.spacing(12),
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(7)
        }
    },
    text: {
        fontSize: theme.spacing(2.5),
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(2)
        }
    },
    button: {
        textTransform: 'none',
        fontSize: '14px',
        color: 'white',
        backgroundColor: '#1C205F',
        width: '150px',
    },
    imageContainer: {
        textAlign: 'right',
        marginTop: '20px',
    },
    image: {
        width: '100%',
    }
}));

const Discover = React.forwardRef((props, ref) =>  {
    const classes = useStyles();

    return (
        <Grid container justify={"flex-end"} alignItems={"center"} className={classes.root} ref={ref}>
            <Grid item className={classes.textContainer}>
                <Typography variant={"h1"} component={"h1"} className={classes.title} paragraph>
                    CoderKaine
                </Typography>
                <Typography className={classes.text} paragraph>Libérateur d’intelligence collective</Typography>
                <Button className={classes.button} onClick={() => props.scrollTo("essence")}>Découvrir</Button>
            </Grid>
            <Grid item md={6} lg={6} xl={6} className={classes.imageContainer}>
                <img alt={""} src={"/image/imgDiscover.svg"} className={classes.image}/>
            </Grid>
        </Grid>
    )
});

Discover.propTypes  = {
    scrollTo: PropTypes.func.isRequired,
};

export default Discover
