import React from 'react';
import {Hidden, makeStyles, Typography} from "@material-ui/core";
import ValourComponent from "./ValourComponent";

const useStyles = makeStyles(() => ({
    root: {
        width: '80%',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '100px',
        marginTop: '50px',
        marginBottom: '50px',
    },
    title: {
        color: '#1C205F',
    },
    rectangle: {
        width: '100px',
        height: '8px',
        borderRadius: '10px',
        backgroundColor: '#E3ECFF',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '4%',
        marginTop: '14px',
    },
    componentContainer: {
        display: 'flex',
        width: '80%',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    componentContainerXS: {
        display: 'flex',
        width: '80%',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: '50px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const Valour = React.forwardRef((props, ref) =>  {
    const classes = useStyles();

    return (
        <div className={classes.root} ref={ref}>
            <div>
                <Typography variant={"h3"} className={classes.title}>Nos valeurs</Typography>
                <div className={classes.rectangle}/>
            </div>
            <Hidden only={'xs'}>
                <div className={classes.componentContainer}>
                    <ValourComponent image={'/image/imgEssenceOne.svg'}     text={"Confiance en l'Homme"}/>
                    <ValourComponent image={'/image/imgEssenceTwo.svg'}     text={"Liberté d’être soi"}/>
                    <ValourComponent image={'/image/imgEssenceThree.svg'}   text={"Créateur de bonheur"}/>
                </div>
                <div className={classes.componentContainer}>
                    <ValourComponent image={'/image/imgEssenceFour.svg'}    text={"Amour du client"}/>
                    <ValourComponent image={'/image/imgEssenceFive.svg'}    text={"Excellence technologique"}/>
                    <ValourComponent image={'/image/imgEssenceSix.svg'}     text={"Bienveillance"}/>
                </div>
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <div className={classes.componentContainerXS}>
                    <ValourComponent image={'/image/imgEssenceOne.svg'}     text={"Confiance en l'Homme"}/>
                    <ValourComponent image={'/image/imgEssenceTwo.svg'}     text={"Liberté d’etre soi"}/>
                    <ValourComponent image={'/image/imgEssenceThree.svg'}   text={"Créateur de bonheur"}/>
                    <ValourComponent image={'/image/imgEssenceFour.svg'}    text={"Amour du client"}/>
                    <ValourComponent image={'/image/imgEssenceFive.svg'}    text={"Excellence technologique"}/>
                    <ValourComponent image={'/image/imgEssenceSix.svg'}     text={"Bienveillance"}/>
                </div>
            </Hidden>
        </div>
    )
});

export default Valour
