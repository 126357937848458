import React from 'react';
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import AppBar from "../AppBar";
import Essence from "../../components/Essence";
import Discover from "../../components/Discover";
import Valour from "../../components/Valour";
import Produits from "../../components/Produits";
import Team from "../../components/Team";
import Contact from "../../components/Contact";
import Footer from "../Footer";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
}));

function MainLayout() {
    let refDiscover = React.createRef();
    let refValour = React.createRef();
    let refEssence = React.createRef();
    let refProduits = React.createRef();
    let refTeam = React.createRef();
    let refContact = React.createRef();

    const classes = useStyles();
    const theme = createMuiTheme({
        typography: {
            fontFamily: [
                'Montserrat',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    });

    function scrollTo(str) {
        switch (str) {
            case "home":
                refDiscover.current.scrollIntoView({behavior: 'smooth'});
                break;
            case "valour":
                refValour.current.scrollIntoView({behavior: "smooth"});
                break;
            case "essence":
                refEssence.current.scrollIntoView({behavior: "smooth"});
                break;
            case "produits":
                refProduits.current.scrollIntoView({behavior: "smooth"});
                break;
            case "team":
                refTeam.current.scrollIntoView({behavior: "smooth"});
                break;
            case "contact":
                refContact.current.scrollIntoView({behavior: 'smooth'});
                break;
            default:
                console.log("this ref not exist : " + str);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <AppBar scrollTo={scrollTo}/>
                <Discover scrollTo={scrollTo} ref={refDiscover}/>
                <Essence ref={refEssence}/>
                <Valour ref={refValour}/>
                <Produits ref={refProduits}/>
                <Team ref={refTeam}/>
                <Contact ref={refContact}/>
                <Footer scrollTo={scrollTo}/>
            </div>
        </ThemeProvider>
    )
}

export default MainLayout
