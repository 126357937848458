import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import TeamComponent from "./TeamComponent";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: '100px',
        width: '100%',
        backgroundImage: 'url(/image/bgTeam.png)',
        backgroundSize: '100% 80%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left top 150px',
    },
    paper: {
        boxShadow: '0px 3px 20px #E3ECFF',
        paddingTop: '75px',
        paddingBottom: '100px',
        float: 'right',
        textAlign: 'center',
        width: '100%',
    },
    title: {
        color: '#1C205F',
        marginBottom: '75px',
    }
}));

const Team = React.forwardRef((props, ref) =>  {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} justify={"flex-end"} ref={ref}>
            <Grid item xl={8} lg={10} md={10} sm={12} xs={12}>
                <Paper className={classes.paper}>
                    <Typography variant={"h3"} className={classes.title}>Qui sommes-nous ?</Typography>
                    <TeamComponent
                        teammates = {[
                            {image: '/image/Baptiste.png',    name: 'Baptiste',   surname: '', role: '', link: ''},
                            {image: '/image/Tuan.png',        name: 'Tuan',       surname: '', role: '', link: ''},
                            {image: '/image/Olivier.png',     name: 'Olivier',    surname: '', role: '', link: ''},
                            {image: '/image/Boris.png',       name: 'Boris',      surname: '', role: '', link: ''},
                            {image: '/image/Eddy.png',        name: 'Eddy',       surname: '', role: '', link: ''},
                            {image: '/image/Benjamin.png',    name: 'Benjamin',   surname: '', role: '', link: ''},
                            {image: '/image/Lola.png',        name: 'Lola',       surname: '', role: '', link: ''},
                        ]}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
});

export default Team
