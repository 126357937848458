import React, {useState} from 'react';
import {
    makeStyles,
    Typography,
    Hidden,
    Toolbar,
    Link,
    MenuItem,
    IconButton,
    MenuList,
    Popper,
    Grow,
    Paper,
    ClickAwayListener
} from "@material-ui/core";
import MuiAppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(() => ({
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        paddingLeft: '2%',
    },
    logo: {
        width: '200px',
        "&:hover": {cursor: 'pointer'},
    },
    spacer: {
        flexGrow: 1,
    },
    button: {
        "&:hover": {cursor: 'pointer'},
        marginLeft: '2%',
        marginRight: '2%',
    },
    buttonSelected: {
        "&:hover": {cursor: 'pointer'},
        marginLeft: '2%',
        marginRight: '2%',
        fontWeight: '900',
    },
    textButton: {
        textTransform: 'none',
        textAlign: 'center',
        color: '#1C205F',
        fontSize: "18px",
        fontWeight: "inherit",
    },
}));

function AppBar(props) {
    const {scrollTo} = props;
    const classes = useStyles();
    const [locationMenu, setLocationMenu] = useState(null);
    const [clicked, setClicked] = useState("/");

    function buttonClick(link) {
        setClicked(link);
    }

    const handleClose = (event) => {
        if (locationMenu && locationMenu.contains(event.target)) {
            return;
        }

        setLocationMenu(null);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setLocationMenu(null);
        }
    }

    return (
        <MuiAppBar position="sticky" className={classes.appBar}>
            <Toolbar>
                <Hidden only={["xs"]}>
                    <img alt={""} src={"/image/logo.png"} className={classes.logo}
                         onClick={() => {buttonClick('/'); scrollTo("home")}}/>
                    <div className={classes.spacer}/>
                    <Link variant={'button'}
                          onClick={() => {buttonClick('/'); scrollTo("home")}}
                          className={
                              clicked === '/' ? classes.buttonSelected : classes.button
                          }
                          underline={'none'}
                    >
                        <Typography variant={'h5'} className={classes.textButton}>Accueil</Typography>
                    </Link>
                    <Link variant={'button'}
                          onClick={() => {buttonClick('/valour'); scrollTo("valour")}}
                          className={
                              clicked === '/valour' ? classes.buttonSelected : classes.button
                          }
                          underline={'none'}
                    >
                        <Typography variant={'h5'} className={classes.textButton}>Valeurs</Typography>
                    </Link>
                    <Link variant={'button'}
                          onClick={() => {buttonClick('/produits'); scrollTo("produits")}}
                          className={
                              clicked === '/produits' ? classes.buttonSelected : classes.button
                          }
                          underline={'none'}
                    >
                        <Typography variant={'h5'} className={classes.textButton}>Produits</Typography>
                    </Link>
                    <Link variant={'button'}
                          onClick={() => {buttonClick('/team'); scrollTo("team")}}
                          className={
                              clicked === '/team' ? classes.buttonSelected : classes.button
                          }
                          underline={'none'}
                    >
                        <Typography variant={'h5'} className={classes.textButton}>Équipe</Typography>
                    </Link>
                    <Link variant={'button'}
                          onClick={() => {buttonClick('/contact'); scrollTo("contact")}}
                          className={
                              clicked === '/contact' ? classes.buttonSelected : classes.button
                          }
                          underline={'none'}
                    >
                        <Typography variant={'h5'} className={classes.textButton}>Contact</Typography>
                    </Link>
                </Hidden>
                <Hidden only={['md', 'sm', 'lg', 'xl']}>
                    <img alt={""} src={"/image/logo.png"} className={classes.logo}
                         onClick={() => {buttonClick('/'); scrollTo("home")}}/>
                    <div className={classes.spacer}/>
                    <IconButton onClick={(event) => setLocationMenu(event.currentTarget)}>
                        <MenuIcon/>
                    </IconButton>
                    <Popper open={!!locationMenu} anchorEl={locationMenu} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList onKeyDown={handleListKeyDown}>
                                            <MenuItem onClick={() => {
                                                setLocationMenu(null);
                                                buttonClick('/');
                                                scrollTo("home")
                                            }}>
                                                <Typography variant={'h5'} className={classes.textButton}>Accueil</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                setLocationMenu(null);
                                                buttonClick('/offer');
                                                scrollTo("valour")
                                            }}>
                                                <Typography variant={'h5'} className={classes.textButton}>Valeurs</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                setLocationMenu(null);
                                                buttonClick('/produits');
                                                scrollTo("produits")
                                            }}>
                                                <Typography variant={'h5'} className={classes.textButton}>Produits</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                setLocationMenu(null);
                                                buttonClick('/team');
                                                scrollTo("team")
                                            }}>
                                                <Typography variant={'h5'} className={classes.textButton}>Équipe</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                setLocationMenu(null);
                                                buttonClick('/contact');
                                                scrollTo("contact")
                                            }}>
                                                <Typography variant={'h5'} className={classes.textButton}>Contact</Typography>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Hidden>
            </Toolbar>
        </MuiAppBar>
    )
}

export default AppBar
