import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center',
        maxWidth: '150px',
        height: '220px',
    },
    image: {
        width: '100px',
        height: '100px',
        padding: '20px',
        marginBottom: '10px',

        backgroundColor: '#E3ECFF',
        borderRadius: '10px',
    },
}));

function ValourComponent(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img alt={""} src={props.image} className={classes.image}/>
            <Typography variant={"subtitle2"}>{props.text}</Typography>
        </div>
    )
}

ValourComponent.propTypes  = {
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default ValourComponent
