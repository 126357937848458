import React, {useState} from 'react';
import {Grid, makeStyles, Typography, TextField, Button} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
        paddingTop: '100px',
    },
    inputZone: {
        marginBottom: '40px',
        marginTop: '12px',
        marginRight: '10px',
        marginLeft: '10px',
        width: '300px',
        height: '30px',
    },
    inputZoneXL: {
        marginBottom: '40px',
        marginTop: '12px',
        marginRight: '10px',
        marginLeft: '10px',
        width: '400px',
        height: '30px',
    },
    inputZoneMessageXS: {
        marginBottom: '40px',
        marginTop: '8px',
        width: '93%',
        marginRight: '10px',
        marginLeft: '10px',
        maxWidth: '640px'
    },
    inputZoneMessage: {
        marginBottom: '40px',
        marginTop: '8px',
        width: '98%',
        marginRight: '10px',
        marginLeft: '10px',
        maxWidth: '640px'
    },
    inputZoneMessageXL: {
        marginBottom: '40px',
        marginTop: '8px',
        width: '820px',
        marginRight: '10px',
        marginLeft: '10px',
    },
    title: {
        color: '#1C205F',
        marginBottom: '20px',
    },
    text: {
        fontSize: '14px',
        marginBottom: '75px',
    },
    button: {
        background: '#1C205F',
        color: 'white',
        float: 'right',
        marginRight: '15px',
    },
    buttonXL: {
        background: '#1C205F',
        color: 'white',
        float: 'right',
        marginRight: '10px',
    },
    snackbar: {
        backgroundColor: '#1C205F',
    },
    container: {
        textAlign: 'left',
        maxWidth: '640px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    containerXL: {
        textAlign: 'left',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '840px',
    }
}));

const Contact = React.forwardRef((props, ref) =>  {
    const classes = useStyles();

    const [name, setName]= useState({value: "", error: ""});
    const [company, setCompany] = useState({value: "", error: ""});
    const [phone, setPhone] = useState({value: "", error: ""});
    const [message, setMessage] = useState({value: "", error: ""});
    const [snack, setSnack] = useState({isOpen: false, message: "", class: ""});

    function formatName(nameValue) {
        if(nameValue){
            let nameFormatted = nameValue.replace(/[^a-zA-Z ]/g, '');
            if (nameValue !== nameFormatted)
                setName({value: nameValue, error: 'Nom et prenom peut contenir uniquement des lettres'});
            else
                setName({value: nameValue, error: ''});
        } else
            setName({value: nameValue, error: 'Veuillez renseigner votre nom et prénom'});
    }
    function formatMessage(messageValue) {
        if (messageValue)
            setMessage({value: messageValue, error: ''});
        else
            setMessage({value: messageValue, error: 'Votre message est trop court'});
    }
    function formatPhone(phoneValue) {
        let formattedStr = phoneValue.replace(/[^0-9]/g, '');
        if (formattedStr.length === phoneValue.length && (formattedStr.length === 10 || phoneValue.length === 0))
            setPhone({value: phoneValue, error:''});
        else
            setPhone({value: phoneValue, error:'Votre numéro de téléphone doit contenir 10 chiffres'});

    }

    const handleClose = () => {
        setSnack({isOpen: false, message: ""})
    };

    function onSend() {
        formatMessage(message.value);
        formatName(name.value);
        formatPhone(phone.value);

        if(!name.error && !message.error && !phone.error && !!name.value && !!message.value) {
            let mail = "mailto:contact@coderkaine.com?"
                + "subject=Contact"
                + "&body=Nom : " + name.value
                + " Tel : ".concat(!!phone.value ? phone.value : "...")
                + " Entreprise : ".concat(!!company.value ? company.value : "...")
                + " Message : " + message.value;

            window.location.href = mail;
        }
    }

    return (
        <div className={classes.root} ref={ref}>
            <Typography variant={"h3"} className={classes.title}>Contactez-nous</Typography>
            <Typography variant={"subtitle2"} className={classes.text}>
                Une question ? Ecrivez-nous ou chattez avec nous, nous nous ferons un plaisir de vous lire.
            </Typography>
            <Grid container justify={'center'}>
                <Grid item xl={5} lg={6} md={8} sm={10} xs={12}>
                    <Hidden only={['xs', 'sm', 'md', 'lg']}>
                        <div className={classes.containerXL}>
                            <TextField
                                className={classes.inputZoneXL}
                                onChange={(e) => formatName(e.target.value)}
                                error={name.error !== ''}
                                helperText={name.error}
                                value={name.value}
                                label={'Votre nom'}
                            />
                            <TextField
                                className={classes.inputZoneXL}
                                onChange={(e) => setCompany({value: e.target.value, error:''})}
                                error={company.error !== ''}
                                helperText={company.error}
                                value={company.value}
                                placeholder={'Optionnel'}
                                label={'Votre entreprise'}
                            />
                            <TextField
                                className={classes.inputZoneXL}
                                onChange={(e) => formatPhone(e.target.value)}
                                error={phone.error !== ''}
                                helperText={phone.error}
                                value={phone.value}
                                placeholder={'Optionnel'}
                                label={'Votre numéro de téléphone'}
                            />
                            <TextField
                                multiline
                                rows={4}
                                className={classes.inputZoneMessageXL}
                                onChange={(e) => formatMessage(e.target.value)}
                                error={message.error !== ''}
                                helperText={message.error}
                                value={message.value}
                                label={'Votre message'}
                            />
                            <Button variant="contained" className={classes.buttonXL} onClick={() => onSend()}>
                                ENVOYER
                            </Button>
                        </div>
                    </Hidden>
                    <Hidden only={['xs', 'xl']}>
                        <div className={classes.container}>
                            <TextField
                                className={classes.inputZone}
                                onChange={(e) => formatName(e.target.value)}
                                error={name.error !== ''}
                                helperText={name.error}
                                value={name.value}
                                label={'Votre nom'}
                            />
                            <TextField
                                className={classes.inputZone}
                                onChange={(e) => formatPhone(e.target.value)}
                                error={phone.error !== ''}
                                helperText={phone.error}
                                value={phone.value}
                                placeholder={'Optionnel'}
                                label={'Votre numéro de téléphone'}
                            />
                            <TextField
                                className={classes.inputZoneMessage}
                                onChange={(e) => setCompany({value: e.target.value, error:''})}
                                error={company.error !== ''}
                                helperText={company.error}
                                value={company.value}
                                placeholder={'Optionnel'}
                                label={'Votre entreprise'}
                            />
                            <TextField
                                multiline
                                rows={4}
                                className={classes.inputZoneMessage}
                                onChange={(e) => formatMessage(e.target.value)}
                                error={message.error !== ''}
                                helperText={message.error}
                                value={message.value}
                                label={'Votre message'}
                            />
                            <Button variant="contained" className={classes.button} onClick={() => onSend()}>
                                ENVOYER
                            </Button>
                        </div>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div className={classes.container}>
                            <TextField
                                className={classes.inputZoneMessageXS}
                                onChange={(e) => formatName(e.target.value)}
                                error={name.error !== ''}
                                helperText={name.error}
                                value={name.value}
                                label={'Votre nom'}
                            />
                            <TextField
                                className={classes.inputZoneMessageXS}
                                onChange={(e) => formatPhone(e.target.value)}
                                error={phone.error !== ''}
                                helperText={phone.error}
                                value={phone.value}
                                placeholder={'Optionnel'}
                                label={'Votre numéro de téléphone'}
                            />
                            <TextField
                                className={classes.inputZoneMessageXS}
                                onChange={(e) => setCompany({value: e.target.value, error:''})}
                                error={company.error !== ''}
                                helperText={company.error}
                                value={company.value}
                                placeholder={'Optionnel'}
                                label={'Votre entreprise'}
                            />
                            <TextField
                                multiline
                                rows={4}
                                className={classes.inputZoneMessageXS}
                                onChange={(e) => formatMessage(e.target.value)}
                                error={message.error !== ''}
                                helperText={message.error}
                                value={message.value}
                                label={'Votre message'}
                            />
                            <Button variant="contained" className={classes.button} onClick={() => onSend()}>
                                ENVOYER
                            </Button>
                        </div>
                    </Hidden>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snack.isOpen}
                onClose={handleClose}
                autoHideDuration={3000}
            >
                <SnackbarContent
                    message={<span>{snack.message}</span>}
                    className={classes.snackbar}
                />
            </Snackbar>
        </div>
    )
});

export default Contact